%share-style1 {
    display: flex;
    padding-top: 0;
    padding-right: 0;
    align-items: center;
    li {
        position: relative;
        margin-right: 5px;
        &:last-child {
            margin-right: 0;
        }

        // separator
        // &:after {
        //     content: "";
        //     position: absolute;
        //     display: block;
        //     width: 1px;
        //     height: 70%;
        //     top: 15%;
        //     background-color: $brown;
        //     right: 0px;
        // }
    }
    a {
        font-size: 1rem;
        width: rem(51);
        height: rem(51);
        border: 2px solid $white;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        &:hover {
            color: $base;
            border-color: $base;
        }
    }
}
%share-style2 {
    a {
        width: 3rem;
        height: 3rem;
        font-size: 1rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin: 0 5px;
        background-color: #707070;
        color: #fff;
        border-radius: 50%;
        &:before {
            font-size: 1.5rem;
        }
        &:hover {
            color: #fff;
            &.icon-facebook {
                background-color: #3B5998;
            }
            &.icon-pinterest {
                background-color: #CB2027;
            }
            &.icon-youtube {
                background-color: #FF0000;
            }
            &.icon-twitter {
                background-color: #7ED0E2;
            }
            &.icon-linkedin-square {
                background-color: #486AAE;
            }
            &.icon-instagram {
                background-color: #506897;
            }
            &.icon-linkedin {
                background-color: #007BB6;
            }
        }
    }
}
%share-style3 {
    a {
        width: 2rem;
        height: 2rem;
        font-size: 1.5em;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 5px;
        background-color: $red;
        color: #fff;
        &:before {
            font-size: .7em;
        }
        &:hover {
            color: $gray;
        }
    }
}
%share-style4 {
    a {
        width: 3rem;
        height: 3rem;
        font-size: 1rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin: 0 5px;
        background-color: #707070;
        color: #fff;
        &:before {
            font-size: 1.5rem;
        }
        color: #fff;
        &.icon-facebook {
            background-color: #3B5998;
        }
        &.icon-pinterest {
            background-color: #CB2027;
        }
        &.icon-youtube {
            background-color: #FF0000;
        }
        &.icon-twitter {
            background-color: #7ED0E2;
        }
        &.icon-linkedin-square {
            background-color: #486AAE;
        }
        &.icon-instagram {
            background-color: #506897;
        }
        &.icon-linkedin {
            background-color: #007BB6;
        }
        &.icon-envelope-o {
            background-color: #6F7173;
        }
    }
}
%ulcolordots {
    li {
        list-style-type: none;
        position: relative;
        padding-left: 1em;
        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: $blue;
            display: inline-block;
        }
    }
}
%ultriangles {
    li {
        list-style-type: none;
        position: relative;
        padding-left: 1.5em;
        &:before {
            content: "\f0da" !important;
            font-weight: 900;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            display: inline-block;
        }
    }
}
%default-form {
    position: relative;
    // ::placeholder {
    //     text-transform: uppercase;
    //     background-color: #EAE5DC;
    // }
    input, select, textarea {
        color: $base;
        border: 1px solid transparent;
        @include fluid-font(22px);
    }
    label {
        color: $white;
        display: block;
        text-align: left;
        padding-bottom: .2em;
        &.file_upload {
            display: inline-flex;
            align-items: flex-start;
            input[type=file]{
                position: absolute;
                visibility: hidden;
                opacity: 0;
                display: none;
            }
            mark {
                background-color: transparent;
                color: #fff;
                opacity: 1;
                margin-left: .5em;
                @include transition;
            }
            .btn {
                cursor: pointer;
                &.empty {
                    background-color: transparent;
                    color: #CBCBCB;
                    border: 1px solid #CBCBCB;
                }
                &:not(.empty):hover {
                    & + mark {
                        opacity: 0;
                    }
                }
            }
        }
    }
    button.btn {
        justify-content: center;
        min-width: 190px;
        font-size: rem(21);
        font-weight: 500;
    }
    input, textarea {
        width: 100%;
    }
    input {
        height: 2.63em;
        padding: 0 1.5em;
    }
    input[type="checkbox"],
    input[type="radio"] {
        padding: 0;
    }
    .select-wrapper {
        height: 2.63rem;
        position: relative;
        @include fluid-font(19px);
        &:before {
            position: absolute;
            right: .2em;
            top: 50%;
            transform: translateY(-50%);
            font-size: 3em;
            z-index: 10;
            pointer-events: none;
            color: #fff;
        }
        &:after {
            position: absolute;
            display: block;
            content: "";
            right: 0;
            top: 0;
            width: 1.5em;
            height: 100%;
            background-color: $brown;
            font-size: 2em;
            z-index: 5;
            pointer-events: none;
        }
    }
    select {
        height: 2.63rem;
        padding: 0 1.5em;
        width: 100%;
        border-radius: 0;
        border: none;
        -webkit-appearance: none;
    }
    textarea {
        padding: 1.5em;
        // margin-bottom: 1em;
    }
    .row {
        padding-top: 0px;
        padding-bottom: 0px;
        .col {
            margin-bottom: 2em;
            
            &.fg-2 {
                flex-grow: 2;
            }
            &.fg-3 {
                flex-grow: 3;
            }
            &.fg-4 {
                flex-grow: 4;
            }
            &.file-upload-container {
                text-align: right;
                &.has-danger {
                    border: 1px solid #f00;
                    mark {
                        color: #f00;
                    }
                }
            }
            
            @include bdown(md) {
                margin-bottom: 15px;
            }
        }
    }
    // .checkbox input,
    // .radio input {
    //     display: none;
    // }
    // .checkbox label,
    // .radio label {
    //     position: relative;
    //     padding-left: 3em;
    //     line-height: 2em;
    //     cursor: pointer;
    //     &:before {
    //         content: "";
    //         position: absolute;
    //         display: block;
    //         left: 0;
    //         top: 0;
    //         width: 2em;
    //         height: 2em;
    //         background-color: #eae5dc;
    //         border: 1px solid #9b918a;
    //     }
    //     &:after {
    //         display: block;
    //         position: absolute;
    //         left: 0;
    //         top: 0;
    //         padding-left: .1em;
    //         content: "";
    //         @include font-count(20, 32);
    //         color: $blue;
    //     }
    // }
    
    // .checkbox input:checked + label,
    // .radio input:checked + label{
    //     &:after {
    //         content: "\e90c";
    //         font-family: $icomoon;
    //     }
    // }
    .inline-group {
        display: flex;
        justify-content: flex-start;
    }
    .captcha-inside {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        input {
            flex-basis: 50%;
        }
        @include bdown(md) {
            justify-content: flex-start;
            input {
                flex-basis: auto;
            }
        }
    }
}