//Imports
@import "qb.css";
@import "fancybox.css";
@import "justifiedGallery.min.css";
@import "fontawesome/fontawesome.scss";
@import "components/components.scss";
@import "base/base.scss";
@import "parts/parts.scss";

.hidden-mobile {
    @include bdown(md) {
        display: none !important;
    }
}
.hidden-desktop {
    @include bup(md) {
        display: none !important;
    }
}

.clearfix {
    &:after{
        display: table;
        content: "";
        clear: both;
    }
}
