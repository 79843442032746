body {
    background-color: #F6F6F6;
    &.home {
        background-color: #FFF2EA;
    }
}
img.home-banner {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.staff {
    margin-bottom: rem(95);
    background-image:
        url(../../images/tiled-boxes.png),
        linear-gradient(to top, rgba(245, 172, 138, 1) 0, rgba(252, 207, 186, 1) 55%,  rgba(245, 172, 138, 0) 100%);
    .text-block {
        padding: 2rem;
    }
    &-img {
        // width: 100%;
        // height: 100%;
        // object-fit: cover;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    &:after {
        content: "";
        display: block;
        background-image: linear-gradient(to right, rgb(246, 246, 246), rgb(150, 217, 238));
        height: rem(33);
        // transform: translateY(100%);
    }
    @include bdown(md){
        .row {
            display: flex;
            flex-direction: column-reverse;
        }
    }
}
.contact-item {
    margin-top: 2rem;
    &:first-of-type {
        margin-top: 3rem;
    }
    h3 {
        color: $pink;
    }
    h3, p {
        line-height: 1;
    }
    p {
        margin-bottom: 0;
    }
    a {
        text-decoration: none;
        &:hover {
            color: $pink;
        }
    }
    &--info {
        font-size: rem(40);
    }
}

.capabilities {
    &-form {
        h4 {
            font-family: $saira;
        }
        .inner {
            display: flex;
            input {
                border: 2px solid $pink;
                height: rem(73);
                min-height: 0;
                font-family: $bebas;
                font-size: rem(26);
                @include letter-spacing(600);
                padding: 0 2em;
                &[type="password"]{
                    flex: 1 0 80%;
                    text-align: center;
                }
                &[type="submit"]{
                    flex: 0 1 auto;
                    background-color: $pink;
                    color: $white;
                    cursor: pointer;
                    @include transition;
                    &:hover {
                        color: $base;
                    }
                }
            }
        }
    }
    &-inside {
        @include bup(md){
            padding-left: 10%;
        }
    }
}

.showcase {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    // grid-template-columns: masonry;
    gap: 2%;
    grid-auto-rows: 1fr;
    opacity: 0 !important;
    &.loaded {
        opacity: 1 !important;
    }
    @include bdown(md){
        grid-template-columns: repeat(1, 1fr);
    }
    &--item {
        position: relative;
        display: block;
        overflow: hidden;
        background-image: linear-gradient(to bottom, #8BC1D4 0, #96C7D8 50%, #B0D5E1 100%);
        p {
            position: absolute;
            z-index: 10;
            // top: 20%;
            top: 25%;
            margin-bottom: 0;
            color: $base;
            // bottom: 0;
            left: 0;
            right: 0;
            padding-left: 16%;
            padding-right: 16%;
            display: flex;
            text-align: center;
            font-family: $bebas;
            word-spacing: 9999999px;
            // @include font-count(24, 47);
            @include font-count(24, 40);
            @include letter-spacing(600);
            @include transition;
            // align-items: center;
            justify-content: center;
            &:after {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                right: 0;
                height: 100%;
                background-position-y: -1em;
                background-image: url(../../images/inside-stripe.svg);
                // background-image: linear-gradient(to bottom, #8BC1D4 0, #96C7D8 25%, rgb(176, 213, 225) 50%, rgba(176, 213, 225, 0) 51%, rgba(176, 213, 225, 0) 100%);
                background-size: 100% 1.25em;
                z-index: -1;
                @include transition;
            }
        }
        img {
            display: block;
            width: 100%;
            height: 100%;
            max-height: 440px;
            object-fit: cover;
            margin-left: auto;
        }
        &:before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-image: url(../../images/showcase-overlay.svg);
            background-repeat: no-repeat;
            background-size: 100% 100%;
            
        }
        &:after {
            content: "";
            display: block;
            position: absolute;
            left: 100%;
            top: 0;
            width: 100%;
            height: 100%;
            background-image: url(../../images/showcase-overlay-moving.png);
            background-repeat: no-repeat;
            background-size: 100% 100%;
            @include transition;
        }
        &:hover {
            &:after {
                left: 0;
            }
            p {
                &:after {
                    right: 100%;
                }
            }
        }
    }
}
.back-link {
    color: $gray;
    text-decoration: none;
    font-family: $bebas;
    @include letter-spacing(800);
    font-size: rem(22);
    &:hover {
        color: $base;
    }
    i {
        margin-right: rem(10);
    }
    @include bup(md){
        padding-left: 3%;
    }
}
.title-block {
    h1 {
        span {
            display: block;
            line-height: 1;
            &:first-child {
                @include font-count(40, 95);
            }
            &:last-child {
                @include font-count(30, 49);
                color: $white;
            }
        }
    }
}
.gradient-line {
    height: rem(28);
    background-image: linear-gradient(to right,  rgba(150, 199, 216, 0) 0, rgba(150, 199, 216, 1) 100%);
}
.inner-content {
    position: relative;
    .featured-img {
        position: absolute;
        right: 0;
        width: 70%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        &.fixed-height {
            height: 100%;
        }
        // align-items: flex-start;
        img {
            max-height: 100%;
            display: block;
            object-fit: contain;
            object-position: right top;
            // object-fit: contain;
            // object-position: right top;
        }
        &:before {
            content: "";
            display: block;
            width: 40px;
            position: relative;
            margin-right: -40px;
            top: 0;
            left: 0;
            background-image: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
        }
        &:after {
            content: "";
            display: block;
            flex-basis: 100%;
            width: 100%;
            height: 40px;
            margin-top: -40px;
            position: relative;
            bottom: 0;
            left: 0;
            background-image: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
        }
    }
    &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: url(../../images/tiled-boxes.png);
        background-position: left top;
        background-repeat: repeat-y;
        z-index: 10;
        pointer-events: none;
    }
    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: url(../../images/inner-bg.svg);
        background-size: 101% 100%;
        z-index: 5;
        // background-position: right top;
        background-repeat: no-repeat;
        pointer-events: none;
    }
    .text-block {
        padding-left: 5%;
        padding-right: 5%;
        position: relative;
        z-index: 5;
        transform: translateY(-1.75rem);
    }
    &.no-img {
        background-color: #98C0D2;
    }
}
.gallery {
    &--container {
        margin-top: -1rem;
        position: relative;
        z-index: 10;
        .inner {
            position: absolute;
            left: 15px;
            right: 15px;
            bottom: 5px;
            font-family: $bebas;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            p {
                color: $white;
                font-weight: 500;
                @include letter-spacing(500);
                text-align: center;
                margin-bottom: 0;
                &:first-child {
                    // font-size: 1.55vw;
                    font-size: rem(24);
                }
                &:last-child {
                    // font-size: 1vw;
                    font-size: rem(14);
                }
            }

        }
    }
}
#gallery {
    display: flex;
    flex-wrap: wrap;
    & > div {
        flex: 0 0 33.33%;
        @include bdown(nm){
            flex: 0 0 50%;
        }
        @include bdown(md){
            flex: 0 0 100%;
        }
        a {
            margin: 3px;
            position: relative;
            display: block;
            &.videolink {
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    z-index: 5;
                    background-image: url(../../images/gallery/play-icon-overlay.png);
                    width: 50%;
                    height: 50%;
                    transform: translate(50%, 50%);
                    top: 0;
                    left: 0;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }
            img {
                width: 100%;
                object-fit: cover;
                // height: 329px;
                display: block;
            }
        }
    }
}
.fancybox-caption__body {
    font-size: rem(27);
}
// .home .video {
//     // height: calc(100vh - 100px);
//     height: 100vh;
//     width: 100%;
//     object-fit: cover;
//     display: block;
//     object-position: center;
// }

// .home .video {
//     height: 100vh;
//     width: 100%;
//     position: relative;
//     // padding-bottom: 56.25%;
//     iframe {
//         height: 100%;
//         left: 0;
//         position: absolute;
//         top: 0;
//         width: 100%;
//     }
// }
.home {
    .main-wrapper {
        overflow: hidden;
    }
    .video {
        position: relative;
        overflow: hidden;
        width: 100vw;
        height: 100vh;
      }
      
    .video iframe {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100vw;
        height: 100vh;
        transform: translate(-50%, -50%);
        // max-width: none;
    }
    
    @media (min-aspect-ratio: 16/9) {
        .video iframe {
            /* height = 100 * (9 / 16) = 56.25 */
            height: 56.25vw;
        }
    }
    @media (max-aspect-ratio: 16/9) {
        .video iframe {
            /* width = 100 / (9 / 16) = 177.777777 */
            width: 177.78vh;
        }
    }

}

.fancybox-button--close {
    svg {
        transform: scale(1.5);
        animation: colorrun;
        animation-iteration-count: infinite;
        animation-duration: 4s;
    }
}
@keyframes colorrun {
    0% {
        color: #ccc;
        transform: scale(1.5);
    }
    50% {
        color: $blue;
        transform: scale(2);
    }
    100% {
        color: #ccc;
        transform: scale(1.5);
    }
}
.bordered-input {
    border: 1px dotted black;
}