.home footer:before {
	background-image: linear-gradient(to right, rgba(245, 172, 138, 1) 0, rgba(245, 172, 138, 1) 50%, rgba(255, 242, 234, 1) 100%);
}
footer {
	background-image: linear-gradient(to right,  rgba(150, 199, 216, 1) 0, rgba(150, 199, 216, 1) 50%, rgba(150, 199, 216, 1) 100%);
	color: $white;
	line-height: 2;
	padding-top: 1.5rem;
	padding-bottom: 1rem;
	font-family: $bebas;
	&:before {
		content: "";
		display: block;
		height: 1rem;
		background-image: linear-gradient(to right, rgba(245, 172, 138, 1) 0, rgba(245, 172, 138, 1) 50%, rgba(255, 242, 234, 1) 100%);
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
	}
	a {
		text-decoration: none;
	}
	.footer-social-links {
		@extend %share-style1;
		justify-content: flex-end;
		li:first-child {
			a {
				margin-left: 0;
			}
		}
		display: flex;
	}
	ul.quick-links {
		display: flex;
		li {
			margin-right: .5rem;
			&:after {
				content: "/";
				display: inline-block;
				padding-left: .5rem;
			}
			&:last-child {
				&:after {
					content: "";
				}
			}
			a {
				&:hover {
					color: $base;
				}
			}
		}
	}
	@include bdown(md){
		ul.quick-links {
			display: block;
			li {
				&:after {
					content: none;
				}
			}
		}
	}
}