$images: '../../images';
$icons: '#{$images}/icons';

$base: #3B4755;
$black: #000000;
$gray: #D8D8D8;
$brown: #9B9B9B;
$dark: #222222;
$green: #82C341;
$blue: #96D9EE;
$pink: #F9AC89;
$black: #000000;
$violet: #432F64;
$violet-alt: #7878B7;
$light-violet: #695C95;
$light-blue: #86D4EF;
$yellow:#E6C821;
$orange: #FF7702;
$red: #E3003B;
$white: #FFFFFF;

//breakpoints
$breakpoints: (
    'hd': 1919px,
    'hg': 1600px,
    'lg': 1400px,
    'nm': 1240px,
    'md': 991px,
    'sm': 768px,
    'xs': 480px,
    'xxs': 385px
)!default;

$bfs: 31;