@import "typography";
html {
    font-size: #{$bfs}px;
    @include bdown(md){
        font-size: 20px;
    }
}
body {
    font-family: $saira;
    color: $base;
    line-height: 1.3;
    position: relative;
    padding-top: 100px;
    &.menu-opened {
        height: 100vh;
        footer {
            display: none;
        }
    }
    @include bup(md){
        height: 100vh;
        overflow: hidden;
        padding-bottom: 124px;
    }
    &.home {
        padding-top: 0;
        @include bup(md){
            padding-bottom: 0;
        }
        header {
            background-color: rgba(255,255, 255, .4);
            z-index: 5;
        }
    }
}
header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    .container {
        height: 100px;
    }
}
footer {
    position: relative;
    @include bup(md){
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 124px;
    }
}
.main-wrapper {
    overflow-y: scroll;
    opacity: 1;
    @include transition;
    &.transparent {
        opacity: 0
    }
}
.container {
    &.wide {
        max-width: 1800px;
    }
    &.wider {
        max-width: 1600px;
    }
}


a[href],
button {
    &:not(.disabled):not(:disabled) {
        cursor: pointer;
    }
    &.disabled,
    &:disabled {
        cursor: default;
        pointer-events: none;
    }
    transition: all .3s ease-in-out;
}
.text--yellow {
    color: $yellow;
}
.text-block{
    p, ul, ol {
        margin-bottom: 1em;
    }
    ul, ol {
        li {
            list-style: disc;
        }
    }
}
h1, h2, h3, h4, h5, h6 {
    margin-bottom: .5em;
    font-family: $bebas;
}
h1 {
    @include sfs(59);
    @include letter-spacing(800);
}
h2 {
    @include sfs(51);
    @include letter-spacing(600);
}
h3 {
    @include sfs(44);
    @include letter-spacing(600);
}
h4 {
    @include sfs(30);
}
h5 {
    @include sfs(26);
}
h6 {
    @include sfs(24);
}
@include bdown(md){
    h1 {
        @include letter-spacing(300);
    }
    h2 {
        @include letter-spacing(200);
    }
    h3 {
        @include letter-spacing(100);
    }
}
.text-left {
    text-align: left;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}

.orange-gradient {
    background-image: linear-gradient(to top, rgba(252, 207, 186, 1) 0, rgba(252, 207, 186, 1) 55%,  rgba(245, 172, 138, 0) 100%);
}

.btn {
    text-transform: uppercase;
    letter-spacing: normal;
    @include letter-spacing(500);
    font-family: $bebas;
    @include sfs(26);
    border: none;
    background-color: $pink;
    color: $white;
    padding: rem(20) rem(18);
    line-height: 1;
    display: inline-flex;
    align-items: center;
    span {
        text-align: left;
    }
    &:hover {
        color: $black;
        background-color: $pink;
    }
    @include bdown(md) {
        padding: 1rem 2rem;
    }
    i {
        padding-right: .5rem;
        font-size: 1.25em;
    }
}

.media-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    iframe {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
    }
}
.img-cover {
    object-fit: cover;
    font-family: 'object-fit: cover;';
}
.text-block {
    h1 {
        text-align: center;
    }
    &.title-block {
        h1 {
            text-align: left;
        }
    }
}

