html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 100%;
    font-weight: inherit;
}
ul {
    li {
        list-style-type: none;
    }
}
button,
input,
select,
textarea {
    margin: 0;
}
html {
    box-sizing: border-box;
}

* {
    &,
    &:before,
    &:after {
        box-sizing: inherit;
    }
}
img,
embed,
iframe,
object,
audio,
video {
    height: auto;
    max-width: 100%;
}
iframe {
    border: 0;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

td,
th {
    padding: 0;
    text-align: left;
}

body {
    margin: 0;
    padding: 0;
}
img {
    width: auto;
    height: auto;
    max-width: 100%;
}

a {
    // display: inline-block;
    color: inherit;
    // @include clear-link;
}

a, button {
    &:not(:disabled),
    &:not(.disabled) {
        cursor: pointer;
    }
}
textarea:focus, input:focus{
    outline: none;
}
.img-cover {
    object-fit: cover;
    font-family: 'object-fit: cover;';
    width: 100%;
    height: 100%;
}
a:not(.btn):hover {
    opacity: initial;
}