header {
    transition: background-color .4s;
    .container {
        position: relative;
        display: flex;
        justify-content: space-between;
    }
    a {
        text-decoration: none;
    }
    ul {
        li {
            list-style-type: none;
        }
    }
    .nav-container {
        display: block;
        position: absolute;
        top: 100%;
        width: 0;
        height: calc(100vh - 224px);
        left: -100%;
        overflow: auto;
        z-index: 50;
        @include transition;
        background-color: #f6f6f6;
        // opacity: 0;
        &.opened {
            width: 100%;
            left: 0;
        }
    }
    nav {
        // width: 100%;
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        align-items: flex-start;
        a {
            position: relative;
            text-transform: uppercase;
            font-family: $bebas;
            @include letter-spacing(800);
            @include font-count(20, 35);
            color: $black;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 0 0 auto;
            @include transition;
            line-height: 26px;
            background-color: transparent;
            padding: .4em .7em;
            &.nav-item-active {
                color: $pink;
            }
            &:hover {
                color: $blue;
            }
        }
    }
    span.nav-toggle {
        text-align: center;
        font-family: 'icomoon';
        font-size: 2.5em;
        color: $blue;
        // min-width: 30px;
        // display: block;
        // margin-bottom: -1rem;
        // transition: transform .3s ease;
        // transform-origin: 50% 50%;
        // // color: $dark-blue;
        &:before {
            content: "\e923";
        }
        // &.close {
        //     transform: rotate(-180deg);
        // }
        &.close:before {
            content: "\e922";
        }
    }
    .nav-toggle {
        background-color: transparent;
        border: none;
        max-width: 86px;
        rect {
            transform-origin: center;
            @include transition;
        }
        #Rectangle_2294_copy {
            opacity: 1;
        }
        &.close {
            svg {
                #Rectangle_2294 {
                    transform: translateX(-25%) translateY(25%) rotate(45deg);
                }
                #Rectangle_2294_copy {
                    opacity: 0;
                }
                #Rectangle_2294_copy_2 {
                    transform: translateX(-25%) translateY(-25%) rotate(-45deg);
                }
            }
        }
        svg {
            width: 100%;
            @include transition;
        }
    }
    @include bdown(md){
        .nav-toggle {
            max-width: 60px;
        }
        .container > a {
            max-width: 50%;
        }
    }
}
.home header .nav-container.opened {
    background-color: #fff2ea;
}
